import Vue from 'vue'
import App from './App.vue'
import store from './store'

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import VueSocketIO from "vue-socket.io";
//import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

//const options = { path: '/my-app/' };
console.log(process.env.VUE_APP_SOCKETIO + process.env.VUE_APP_SOCKETIO_PATH);

const options = {
  "force new connection" : true,
  "reconnectionAttempts": "Infinity",
  "timeout" : 10000,
  "transports": ['websocket', 'polling', 'flashsocket'],
  "path": process.env.VUE_APP_SOCKETIO_PATH
};

console.log(process.env.VUE_APP_SOCKETIO + process.env.VUE_APP_SOCKETIO_PATH);

var myHost = process.env.VUE_APP_SOCKETIO;

Vue.use(new VueSocketIO({
  debug: true,
  connection: io(myHost, options),
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  },
  withCredentials: true,
  extraHeaders: {
    "centralApp": "centralApp"
  }
}));



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//fas
import { faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGraduate, faChild, faCrown, faBaby } from '@fortawesome/free-solid-svg-icons'
//far
//import { faSmile, faMeh, faPauseCircle } from '@fortawesome/free-regular-svg-icons'
//fab
//import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGraduate, faChild, faCrown, faBaby)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  store,
  BootstrapVue,
  IconsPlugin,
  FontAwesomeIcon,
  render: h => h(App),
}).$mount('#app')
