const state = {

    performanceTableItems: [],
    performanceTableUserMonth: {},
    actualUser: {},
    performanceTablePlanBusyState: true,
    projectEditStatus: [],
    showPage: false
}

const mutations = {
    SET_ACTUAL_MONTH(state, payload) {
        //console.log('setze Monat: ' + payload);
        state.performanceTableItems.actual_month = payload;
    },
    SAVE_PERFORMANCE_TABLE_ITEMS(state, payload) {
        //console.log(payload);
        state.performanceTableItems = payload;
        state.showPage = true;
    },
    CHANGE_PROJECT_EDIT_STATUS(state, payload) {

        if (state.projectEditStatus[payload] === true) {
            state.projectEditStatus[payload] = false;
        } else {
            state.projectEditStatus[payload] = true;
        }

        //console.log(state.projectEditStatus);

    },

    SAVE_PERFORMANCE_TABLE_ITEM_HOUR(state, payload) {

        var data = state.performanceTableItems.items[payload];

        var hour_cost = data.hour_cost;

        var hours = data.hours.goal;

        var turnover = hour_cost * hours;

        state.performanceTableItems.items[payload].turnover.goal = turnover;

        //console.log(state.performanceTableItems.items[payload]);

    },
    SAVE_PERFORMANCE_TABLE_ITEM_PROJECT_HOUR(state, payload) {

        console.log(payload);
        /*
        var data = state.actualUser;

        var hour_cost = data.hour_cost;

        var hours = data.hours.goal;

        var turnover = hour_cost * hours;

        state.performanceTableItems.items[payload].turnover.goal = turnover;

         */

        //console.log(state.performanceTableItems.items[payload]);

    },

    SET_PERFORMANCE_TABLE_ITEM_HOUR_ACTIVE(state, payload) {

        state.performanceTableItems.items[payload].hours.isActive = true;
        /*
        console.log('active');
        const filteredByKey = Object.fromEntries(
            //Object.entries(state.performanceTableItems.items).filter(([key, value]) => value.index === payload)
            Object.entries(state.performanceTableItems.items).filter(
                (key) => key === payload
            )
        );

        console.log('filtered', filteredByKey);

         */

    },

    SET_PERFORMANCE_TABLE_ITEM_HOUR_INACTIVE(state, payload) {

        state.performanceTableItems.items[payload].hours.isActive = false;

    },

    SAVE_PERFORMANCE_TABLE_USER_MONTH(state, payload) {

        //console.log(payload.year);
        //console.log(payload.month);
        //console.log(payload.user.user_id);
        //console.log(payload.project_id);

        var id = payload.year + '-' + payload.month + '-' + payload.project_id + '-' + payload.user.user_id;

        //console.log(id);

        let obj = {};
        obj[id] = payload;

        //console.log(obj);

        state.performanceTableUserMonth = {...state.performanceTableUserMonth, ...obj}

        state.actualUser = payload;

    },
    SAVE_ACTUAL_USER_PROJECT_GOAL(state, payload) {

        state.actualUser.project_goal = payload;

    },
    SAVE_ACTUAL_USER_WORKING_HOURS(state, payload) {

        state.actualUser.user.month_working_hours_available = payload.month_working_hours_available;
        state.actualUser.user.month_working_hours_planned = payload.month_working_hours_planned;
        state.actualUser.user.month_working_hours_planned_without_active_project = payload.month_working_hours_planned_without_active_project;

    },
    TOGGLE_PERFORMANCE_TABLE_PLAN_BUSY_STATE(state) {

        state.performanceTablePlanBusyState = !state.performanceTablePlanBusyState;

    },
}

const actions = {
    setActualMonth({commit}, payload) {

        if (payload == '') {
            payload = new Date().getMonth() + 1;
        }

        commit('SET_ACTUAL_MONTH', payload);
    },

    savePerformanceTableItems({commit}, payload) {

        commit('SAVE_PERFORMANCE_TABLE_ITEMS', payload);
    },
    changeProjectEditStatus({commit}, payload) {
        commit('CHANGE_PROJECT_EDIT_STATUS', payload);
    },
    changePerformanceTableItemHour({commit}, payload) {
        commit('SAVE_PERFORMANCE_TABLE_ITEM_HOUR', payload);
    },
    setPerformanceTableItemHourActive({commit}, payload) {
        commit('SET_PERFORMANCE_TABLE_ITEM_HOUR_ACTIVE', payload);
    },
    setPerformanceTableItemHourInActive({commit}, payload) {
        commit('SET_PERFORMANCE_TABLE_ITEM_HOUR_INACTIVE', payload);
    },
    savePerformanceTableUserMonth({commit}, payload) {
        commit('SAVE_PERFORMANCE_TABLE_USER_MONTH', payload);
    },
    changePerformanceTableItemProjectHour({commit}, payload) {
        commit('SAVE_PERFORMANCE_TABLE_ITEM_PROJECT_HOUR', payload);
    },
    saveActualUserProjectGoal({commit}, payload) {
        commit('SAVE_ACTUAL_USER_PROJECT_GOAL', payload);
    },
    saveActualUserWorkingHours({commit}, payload) {
        commit('SAVE_ACTUAL_USER_WORKING_HOURS', payload);
    },
    togglePerformanceTablePlanBusyState({commit}) {
        commit('TOGGLE_PERFORMANCE_TABLE_PLAN_BUSY_STATE');
    },


}

const getters = {
    getPerformanceTableItems() {
        return state.performanceTableItems;
    },
    getActualMonth() {
        return state.performanceTableItems.actual_month;
    },
    getProjectEditStatus() {
        return state.projectEditStatus;
    },
    getPerformanceTableUserMonth() {
        return state.performanceTableUserMonth;
    },
    getActualUser() {
        //console.log(state.actualUser.items);

        /*
        if (typeof state.actualUser.items === 'undefined') {

            console.log('undddd');

        }

        if (typeof state.actualUser.items !== 'undefined') {

            console.log('ist da');

            Object.keys(state.actualUser.items).forEach(function(key){
                console.log(key);
                //console.log(state.actualUser.items[key]);

                Object.keys(state.actualUser.items[key]).forEach(function(key2){
                    console.log(key2);
                    console.log(state.actualUser.items[key][key2]);
                    console.log('goal ' + state.actualUser.items[key][key2].goals.goal);
                })
            })

        }

         */

        return state.actualUser;
    },
    getPerformanceTablePlanBusyState() {
        return state.performanceTablePlanBusyState;
    },
    getPageStatus() {
        return state.showPage;
    }
}

const performanceTable = {
    state,
    mutations,
    actions,
    getters

}

export default performanceTable;