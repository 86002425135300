<template>
  <div>

    <b-modal id="modal-user-month" :title="createActualUserModalTitle" size="xl" @hidden="loadData">

      <p class="h5">{{ getActualUserName }}</p>
      <p class="h5">{{ getActualProjectName }}</p>

      <table class="table mt-4 table-sm" head-variant="light">
        <tr>
          <td class="small">Std. / Tag:</td>
          <td class="small">{{ Number(getActualUserWorkingTimePerDay).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small">Std. / Monat:</td>
          <td class="small">{{ Number(getActualUserWorkingTimePerMonth).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small">Std. Monat verbleibend:</td>
          <td class="small">{{ Number(getActualUserWorkingTimePerMonthAvailable).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small">Verplant gesamt:</td>
          <td class="small">{{ Number(getActualUserHoursPlanned).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small"><strong>Offene, verplanbare Std.:</strong></td>
          <td
              v-if="Number(getActualUserHoursAvailable) >= 0"
              class="small text-success"><strong>{{ Number(getActualUserHoursAvailable).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</strong></td>
          <td
              v-else
              class="small text-danger"><strong>{{ Number(getActualUserHoursAvailable).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</strong></td>
        </tr>

        <tr>
          <td class="small"><strong>Projektziel:</strong></td>
          <td class="small"><strong>{{ Number(getActualUserProjectGoal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</strong></td>
        </tr>
        <tr>
          <td class="small">Verplant gesamt:</td>
          <td class="small">{{ Number(getActualUserProjectGoalPlanned).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small">Verplant durch andere:</td>
          <td class="small">{{ Number(getActualUserProjectGoalOther).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
        <tr>
          <td class="small"><strong>Verplant für das aktuelle Projekt:</strong></td>
          <td class="small"><strong>{{ Number(getActualUserProjectGoalUser).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</strong></td>
        </tr>
        <tr>
          <td class="small">offen:</td>
          <td
            v-if="getActualUserProjectGoalAvailable >= 0"
            class="text-success small"
          >{{ Number(getActualUserProjectGoalAvailable).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
          <td
              v-else
              class="text-danger small"
          >{{ Number(getActualUserProjectGoalAvailable).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</td>
        </tr>
      </table>

      <b-table sticky-header="800px" small striped hover bordered :items="getActualUser.items" :fields="getActualUser.fields" caption-top>

        <template #table-caption><span class="ml-1 small">Bitte maximal 7 Std. am Tag verplanen.</span></template>

        <template #cell()="data">

          <b-row>
            <b-col
                v-if="data.value.weekday_number == 1"
                class="small text-left kw">{{ data.value.kw }}</b-col>
            <b-col class="text-right">
              <span
                v-if="isToday(data.value.day)"
              ><strong>{{ data.value.day_short }}</strong></span>

              <span
                  v-else
              >{{ data.value.day_short }}</span>
              </b-col>
          </b-row>

          <span
            v-if="!data.value.is_cleared && !data.value.no_work"
          >

            <b-row class="mt-2">
              <b-col class="small"><strong>Ziel:</strong></b-col>
            </b-row>
            <b-row>
              <b-col cols="7" class="small">Projekt:</b-col>
              <b-col class="small text-right">

                  <span
                      v-if="Number(data.value.goals.goal) > 0"
                      class="text-info alert-info"
                  ><strong>{{ Number(data.value.goals.goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</strong></span>

                  <span
                      v-else
                  >{{ Number(data.value.goals.goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="7" class="small">
                <b-button
                    v-if="checkIfOtherProjectGoals(data.value.goals.projects)"
                    variant="link" v-b-popover.html="showOtherPlannedProjects(data.value.goals.projects, getActualProjectId)" title="Geplante Projekte" size="sm" class="tooltip-button">andere Projekte:</b-button>

                <span v-else>andere Projekte:</span>

              </b-col>
              <b-col class="small text-right">{{ Number(data.value.goals.sum_external).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h</b-col>
            </b-row>

            <b-row>
              <b-col class="small"><hr class="hr-small"/></b-col>
            </b-row>

            <b-row>
              <b-col cols="7" class="small">Gesamt:</b-col>
              <b-col class="small text-right">{{ Number(data.value.goals.sum).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h</b-col>
            </b-row>



            <div
                v-if="isToday(data.value.day) || !data.value.is_editable"
            >
              <b-row class="mt-2">
                <b-col class="small"><strong>Erreicht:</strong></b-col>
              </b-row>
              <b-row>
                <b-col cols="7" class="small">Projekt:</b-col>
                <b-col class="small text-right">
                  <span
                    v-if="Number(data.value.work.work_on_project) > 0"
                    class="text-info"
                    >{{ Number(data.value.work.work_on_project).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h</span>

                  <span
                      v-else
                  >{{ Number(data.value.work.work_on_project).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h</span>

                </b-col>
              </b-row>

              <b-row>
                <b-col cols="7" class="small">
                  <b-button
                      v-if="checkIfOtherProjectGoals(data.value.work.tasks)"
                      variant="link" v-b-popover.html="showOtherProjects(data.value.work.tasks, getActualProjectId)" title="Andere Projekte" size="sm" class="tooltip-button">andere Projekte:</b-button>
                  <span
                    v-else>andere Projekte</span>
                </b-col>
                <b-col class="small text-right">
                  {{ Number(data.value.work.sum_external).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h
                </b-col>
              </b-row>

              <b-row>
                <b-col class="small"><hr class="hr-small"/></b-col>
              </b-row>

              <b-row>
                <b-col cols="7" class="small">Gesamt:</b-col>
                <b-col class="small text-right">
                  {{ Number(data.value.work.sum).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2})}}h
                </b-col>
              </b-row>
            </div>

            <div
                v-if="data.value.is_editable"
            >
              <b-row>
                <b-col cols="7" class="small mt-3">verfügbar:</b-col>
                <b-col class="small text-right mt-3">
                  <span
                    v-if="calculateSpendableTime(data.value) > 0"
                    class="text-success alert-success"
                    >{{ Number(calculateSpendableTime(data.value)).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</span>
                  <span
                      v-if="calculateSpendableTime(data.value) < 0"
                      class="text-danger"
                  >{{ Number(calculateSpendableTime(data.value)).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</span>
                  <span
                      v-if="calculateSpendableTime(data.value) == 0"
                      class=""
                  >{{ Number(calculateSpendableTime(data.value)).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}h</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7" class="small mt-3">vergeben:</b-col>
                <b-col class="small">
                  <b-form-input
                      v-model.number="data.value.goals.goal"

                      onclick="this.focus();this.select()"
                      class="table-text-input text-right"
                      size="3"
                      @keypress="onlyNumber"
                      @change="saveProjectUserHour(data.value)"
                  />

                  <!--
                  @input="changeHour(data.index)"
                      -->
                </b-col>
              </b-row>
            </div>

          </span>

          <div
              v-else
              style="min-height: 100px; min-width: 100px;"
              class="small text-center"
          >
            <br/>{{ data.value.no_work_reason }}
          </div>


        </template>

      </b-table>

    </b-modal>

  </div>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "performanceTableUserMonth",
  data() {
    return {
      actual_year: String(new Date().getFullYear()),
    }
  },
  computed: {
    ...mapGetters([
      'getActualUser',
      'getActualMonth'
    ]),
    ...mapActions([

    ]),
    ...mapMutations([

    ]),
    getActualUserId() {

      let actualUser = this.getActualUser;

      if (actualUser.user !== undefined) {
        if (actualUser.user.user_id !== undefined) {
          return actualUser.user.user_id;
        }
      }

      return 0;
    },
    createActualUserModalTitle() {

      let actualUser = this.getActualUser;

      if (actualUser.project_name === undefined) return '';

      //var title = actualUser.month_str_full + ' ' + actualUser.year + ', ' + actualUser.project_name + ', ' + actualUser.user.firstname + ' ' + actualUser.user.lastname;

      var title = actualUser.month_str_full + ' ' + actualUser.year;

      return title;
    },
    getActualUserName() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return '';

      var username = actualUser.user.firstname + ' ' + actualUser.user.lastname;

      return username;
    },
    getActualProjectName() {

      let actualUser = this.getActualUser;

      if (actualUser.project_name === undefined) return '';

      return actualUser.project_name;
    },
    getActualProjectId() {

      let actualUser = this.getActualUser;

      if (actualUser.project_id === undefined) return '';

      return actualUser.project_id;
    },
    getActualUserWorkingTimePerDay() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.user.working_time_per_day === undefined) return 0;

      return actualUser.user.working_time_per_day;

    },
    getActualUserWorkingTimePerMonth() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.user.month_working_days === undefined) return 0;

      return actualUser.user.month_working_days * actualUser.user.working_time_per_day;
    },
    getActualUserWorkingTimePerMonthAvailable() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.user.month_working_days_available === undefined) return 0;

      return actualUser.user.month_working_days_available * actualUser.user.working_time_per_day;
    },
    getActualUserProjectGoal() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.project_goal === undefined) return 0;

      return actualUser.project_goal.sum;
    },
    getActualUserProjectGoalPlanned() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.project_goal === undefined) return 0;

      return actualUser.project_goal.planned;
    },
    getActualUserProjectGoalUser() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.project_goal === undefined) return 0;

      return actualUser.project_goal.user;
    },
    getActualUserProjectGoalOther() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.project_goal === undefined) return 0;

      return actualUser.project_goal.other;
    },
    getActualUserProjectGoalAvailable() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      if (actualUser.project_goal === undefined) return 0;

      return actualUser.project_goal.sum - actualUser.project_goal.planned;
    },
    getActualUserHoursPlanned() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      return actualUser.user.month_working_hours_planned;
    },
    getActualUserHoursAvailable() {

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) return 0;

      return actualUser.user.month_working_hours_available;
    },

  },
  methods: {
    ...mapActions([
      'saveActualUserProjectGoal',
      'saveActualUserWorkingHours',
      'togglePerformanceTablePlanBusyState'
    ]),
    test(value) {
      return value;
    },
    showOtherProjects(data, project_id) {
      //console.log(data);

      var str = '<table>';

      for (let i=0; i<data.length; i++) {

        if (data[i].project_id == project_id) continue;

        str += '<tr><td>' + data[i].title + '</td><td align="right">' + Number(data[i].hours).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) + 'h</td></tr>';
      }

      str += '</table>';
      return str;
    },
    showOtherPlannedProjects(data, project_id) {
      //console.log(data);

      var str = '<table>';

      for (let i=0; i<data.length; i++) {

        if (data[i].project_id == project_id) continue;

        str += '<tr><td>' + data[i].project + '</td><td align="right" width="50px">' + Number(data[i].goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) + 'h</td></tr>';
      }

      str += '</table>';
      return str;
    },
    calculateSpendableTime(data) {

      //console.log(data);

      var sum_project = 0;
      var sum_external = 0;
      var working_time_per_day = 0;

      if (data.goals !== undefined) {
        sum_external = data.goals.sum_external;
        sum_project = data.goals.goal;
      }

      let actualUser = this.getActualUser;

      if (actualUser.user !== undefined) {
        working_time_per_day = actualUser.user.working_time_per_day;
      }

      var sum = working_time_per_day - ( sum_external + sum_project );

      /*
      var sum_out = Number(sum).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) + 'h';
      var out = '';

      if (sum > 0) {
        out += '<span class="text-success">' + sum_out + '</span>';
      } else if (sum < 0) {
        out += '<span class="text-danger">' + sum_out + '</span>';
      }

      return out;

       */
      return sum;

    },
    onlyNumber ($event) {

      //console.log($event.keyCode); //keyCodes value
      //console.log($event);
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    saveProjectUserHour(data) {

      //day
      //goal
      //user_id
      //project_id
      //year
      //month

      //console.log('save');
      this.recalculateUserGoals();

      let actualUser = this.getActualUser;

      var goal = data.goals.goal;
      var day = data.day;
      var year = actualUser.year;
      var month = actualUser.month;
      var user_id = actualUser.user.user_id;
      var project_id = actualUser.project_id;

      this.$socket.emit('savePerformanceTableDataProjectUserHour', year, month, day, project_id, user_id, goal);

    },
    isToday(day) {

      const today = new Date().toJSON().slice(0, 10)

      //console.log(day);
      //console.log(today);

      if (day == today) return true;

      return false;

    },
    checkIfOtherProjectGoals(projects) {

      let actualUser = this.getActualUser;

      var project_id = actualUser.project_id;

      var other = false;

      for (let i=0; i<projects.length; i++) {

        if (projects[i].project_id == project_id) continue;

        other = true;

        break;
      }

      return other;

    },
    loadData() {
      this.$store.dispatch('togglePerformanceTablePlanBusyState');
      //console.log(this.getActualMonth);
      //console.log(this.actual_year);
      this.$socket.emit('getPerformanceTableData', this.getActualMonth, this.actual_year);
    },
    recalculateUserGoals() {

      //console.log('berechne Daten neu');

      let actualUser = this.getActualUser;

      if (actualUser.user === undefined) {
        //console.log('abbruch');
        return;
      }

      var project_goal = 0;
      //var project_goal_sum = 0;
      //var project_goal_sum_external = 0;

      for (let i=0; i<actualUser.items.length; i++) {

        //console.log(actualUser.items[i]);

        for (const [key, data] of Object.entries(actualUser.items[i])) {

          //console.log(`${key}: ${data}`);
          //console.log(data);
          //console.log(key);

          if (key == 'banane') {
            //nischt, weiß nicht, wie man ohne key definiert.
          }

          if (data.goals === undefined) continue;

          project_goal += data.goals.goal;
          //project_goal_sum += data.goals.sum;
          //project_goal_sum_external += data.goals.sum_external;

        }

      }

      let data = {};

      //console.log(actualUser.project_goal);

      data.other = actualUser.project_goal.other;
      data.planned = Number(actualUser.project_goal.other) + project_goal; //actualUser.project_goal.planned;
      data.sum = actualUser.project_goal.sum;
      data.user = project_goal; //actualUser.project_goal.user;

      //console.log(data);
      //console.log(project_goal);

      /*
      console.log('goal: ' + project_goal);
      console.log('goal sum: ' + project_goal_sum);
      console.log('goal sum external: ' + project_goal_sum_external);
       */

      this.$store.dispatch('saveActualUserProjectGoal', data);

      let data2 = {};

      data2.month_working_hours_planned = project_goal + Number(actualUser.user.month_working_hours_planned_without_active_project);
      data2.month_working_hours_available = (Number(actualUser.user.month_working_days_available) * Number(actualUser.user.working_time_per_day)) - data2.month_working_hours_planned;
      data2.month_working_hours_planned_without_active_project = Number(actualUser.user.month_working_hours_planned_without_active_project);

      this.$store.dispatch('saveActualUserWorkingHours', data2);

    }

  }
}
</script>

<style scoped>

.kw {
  color: lightgrey;
}

.hr-small {
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.tooltip-button {
  padding: 0px;
  margin: 0px;
  font-size: 100%;
  font-weight: 400;
  border: 0px;
  background: none;
}

.table-text-input {
  padding: 2px;

}

</style>