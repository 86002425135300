<!-- https://bootstrap-vue.org/docs/components/table#john-doe -->
<template>
  <div>

    <performance-table pyear="2021"></performance-table>

  </div>
</template>

<script>

import performanceTable from "@/components/performanceTable";
import { mapGetters } from 'vuex';

export default {
  name: "performancePlan",
  components: {performanceTable},
  computed: {
    ...mapGetters([
      'getPerformanceTableItems',
      'getActualMonth',
      'getProjectEditStatus',
      'getPerformanceTableUserMonth',
      'getPerformanceTablePlanBusyState',
      'getPageStatus'
    ]),
    pageStatus() {
      return this.getPageStatus;
    }
  },
  data() {
    return {
      actual_year: String(new Date().getFullYear()),
    }
  }
}
</script>

<style scoped>

</style>