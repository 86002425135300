import Vue from 'vue'
import Vuex from 'vuex'

import performanceTable from "@/store/modules/performanceTable";

Vue.use(Vuex)

/*
kann in export weggelassen werden, wird ja über die Module gemacht

state: {
  },
  mutations: {
  },
  actions: {
  },
 */
export default new Vuex.Store({

    modules: {
        performanceTable
    }
})