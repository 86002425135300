<template>

  <div>
    <div
        v-if="pageStatus"
    >
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand :href="centralUrl + 'index'"><img :src="baseUrlImg + 'WIZMO_logo_cia.svg'" border="0" width="195" height="49" title="WIZMO Central - Home" /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <b-navbar-nav>
              <b-nav-item :href="centralUrl + 'index'">Startseite</b-nav-item>
            </b-navbar-nav>

            <!--
            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>
            -->

            <b-navbar-nav>
              <b-nav-item :href="centralUrl + 'user_profile'">
                <font-awesome-icon
                    :icon="['fa', 'user']"
                    class="cursor-pointer ml-3 mr-3"
                />
              </b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav>
              <b-nav-item :href="centralUrl + 'logout'">
                <font-awesome-icon
                    :icon="['fa', 'sign-out-alt']"
                    class="cursor-pointer mr-3"
                />
              </b-nav-item>
            </b-navbar-nav>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div
      v-else
      >

      <b-alert variant="info" show>prüfe Login</b-alert>

    </div>


  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "navigation",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImg: process.env.VUE_APP_BASE_URL_IMG,
      centralUrl: process.env.VUE_APP_CENTRAL_URL,
    };
  },
  computed: {
    ...mapGetters([
      'getPerformanceTableItems',
      'getActualMonth',
      'getProjectEditStatus',
      'getPerformanceTableUserMonth',
      'getPerformanceTablePlanBusyState',
      'getPageStatus'
    ]),
    pageStatus() {
      console.log(this.getPageStatus);
      return this.getPageStatus;
    }
  },
  sockets: {
    connect: function () {
      console.log('socket connected navigation');
      //this.$socket.emit('sendCookie', this.getCookie());
    },
  },
  methods:{
    getCookieOld() {

      var cookie = this.$cookies.get('PHPSESSID');

      console.log(cookie);

      if (cookie == null) {
        cookie = process.env.VUE_APP_TESTCOOKIE;
      }

      console.log(cookie);
      // it gets the cookie called `username`
      //const username = this.$cookies.get("username");
      //console.log(username);

      return cookie;
    }
  }
}
</script>

<style scoped>

</style>