<template>
  <div
    v-if="pageStatus"
  >

    <h3>Performance Planung {{ actual_year }}</h3>

    <b-button
        v-for="month in getPerformanceTableItems.months" :key="month.month"
        :variant="month.month == getPerformanceTableItems.actual_month ? 'primary' : 'outline-primary'"
        class="mr-3 mt-3 mb-3"
        @click="setNewMonth(month.month)"
    >{{ month.description }}</b-button>

    <b-table sticky-header="800px" small striped hover
             :items="getPerformanceTableItems.items"
             :fields="getPerformanceTableItems.fields" caption-top
             :busy="getPerformanceTablePlanBusyState"
             :primary-key="index"
    >

      <template #table-caption><span class="ml-3">Ziel (Ziele definiert) / IST</span></template>

      <template #thead-top="data">
        <b-tr>
          <b-td></b-td>
          <b-td>{{ getStatistic('sum_projects') }}</b-td>

          <b-td>{{ getStatistic('sum_ecc') }}</b-td>

          <b-td class="text-nowrap">Ø {{ Number(getStatistic('hour_cost')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €</b-td>

          <b-td class="text-nowrap text-right">
            <span
                v-if="Number(getStatistic('turnover_goal')) < 170000"
                class="text-danger"
            >{{ Number(getStatistic('turnover_goal')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €</span>

            <span
                v-else
            >{{ Number(getStatistic('turnover_goal')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €</span>
            <br/>

            <span
                v-if="Number(getStatistic('turnover_goal')) > Number(getStatistic('turnover_current'))"
                class="text-danger"
            >{{ Number(getStatistic('turnover_current')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €</span>

            <span
                v-else
                class="text-success"
            >{{ Number(getStatistic('turnover_current')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €</span>


            </b-td>

          <b-td class="text-nowrap">
            {{ Number(getStatistic('hours_goal')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
            <br/>

            <span
                v-if="getStatistic('hours_goal') > getStatistic('hours_goal_sum')"
                class="text-danger"
            >({{ Number(getStatistic('hours_goal_sum')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }})</span>

            <span
                v-else
                class="text-success"
            >({{ Number(getStatistic('hours_goal_sum')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }})</span>

            /

            <span
                v-if="getStatistic('hours_goal') > getStatistic('hours_current')"
                class="text-danger"
            >{{ Number(getStatistic('hours_current')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span>

            <span
                v-else
                class="text-success"
            >{{ Number(getStatistic('hours_current')).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span>

          </b-td>

          <b-td
              v-for="field in filterHeader(data.fields)"
              :key="field.key"
              class="text-nowrap"
            >
            {{ Number(getStatisticUser(field.key).goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} /

            <span
                v-if="getStatisticUser(field.key).goal > getStatisticUser(field.key).goal.current"
                class="text-danger"
            >{{ Number(getStatisticUser(field.key).current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span>

            <span
                v-else
                class="text-success"
            >{{ Number(getStatisticUser(field.key).current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span>

          </b-td>

        </b-tr>
      </template>

      <template #cell(customer_type)="data">
        <span
            v-if="data.value.customer_type.customer_type_icon_vue !== undefined && data.value.customer_type.customer_type_icon_vue_group !== undefined"
        >
          <font-awesome-icon
              :icon="[data.value.customer_type.customer_type_icon_vue_group, data.value.customer_type.customer_type_icon_vue]"
              class="small"
          /><br/></span>

      </template>

      <template #cell(project)="data">
        <b-button
            variant="link"
            class="table-link"
            :href="centralUrl + 'customer_overview&show=dashboard&customer_id=' + data.value.natural_person_id"
            target="_blank"
        >
          {{ data.value.title }}
        </b-button>

      </template>

      <template #cell(ecc)="data">

        <span v-if="data.value.firstname == 'unbekannt'">{{ data.value.firstname }}</span>
        <span v-else>{{ data.value.nickname }}</span>

      </template>

      <template #cell(hour_cost)="data">

        {{ Number(data.value).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0}) }} €

      </template>

      <template #cell(turnover)="data">

        <div class="text-nowrap text-right">
        {{ Number(data.value.goal).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €' }}<br/>

        <span
            v-if="Number(data.value.goal) > Number(data.value.current)"
            class="text-danger"
        >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €' }}</span>

        <span
            v-else
            class="text-success"
        >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €' }}</span>
        </div>

      </template>

      <template #cell(hours)="data">

        <div class="text-nowrap">
          <!-- index: {{ data.item.index }} {{ data.index }} -->
          <div
            v-if="data.value.isActive"
          >
            <b-form-input
                v-model.number="data.value.goal"
                onclick="this.focus();this.select()"
                @input="changeHour(data.index)"
                @change="saveHour(data.index)"
                @keypress="onlyNumber"
                class="table-text-input"
                size="3"
            /><br/>
          </div>


          <div
              v-if="!data.value.isActive"
          >
            <b-button
                variant="link"
                class="table-link"
                @click="editHours(data.index)"
            >
            {{ Number(data.value.goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
            </b-button><br/>
          </div>

          (<span
              v-if="Number(data.value.goal) > Number(data.value.goal_sum)"
              class="text-danger"
          >{{ Number(data.value.goal_sum).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>

          <span
              v-else
              class="text-success"
          >{{ Number(data.value.goal_sum).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>)

          /

          <span
              v-if="Number(data.value.goal) > Number(data.value.current)"
              class="text-danger"
          >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>

          <span
              v-else
              class="text-success"
          >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>
        </div>
      </template>

      <template #cell()="data">

        <div
            class="text-nowrap"
            v-if="Number(data.value.goal) == 0 && Number(data.value.current) == 0"
            >
          <b-button
              variant="link"
              class="table-link"
              @click="editUser(data.value.project_id, data.value.user_id)"
              v-b-modal.modal-user-month
          >
            +
          </b-button>
        </div>

        <div
          class="text-nowrap"
          v-else
          >

          <div class="text-nowrap">
            <b-button
                variant="link"
                class="table-link"
                @click="editUser(data.value.project_id, data.value.user_id)"
                v-b-modal.modal-user-month
            >
              {{ Number(data.value.goal).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
            </b-button> /

            <span
                v-if="Number(data.value.goal) > Number(data.value.current)"
                class="text-danger"
            >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>

            <span
                v-else
                class="text-success"
            >{{ Number(data.value.current).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</span>
          </div>

        </div>

      </template>

    </b-table>

    <performance-table-user-month></performance-table-user-month>

  </div>
</template>

<script>

import performanceTableUserMonth from "@/components/performanceTableUserMonth";
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "performanceTable",
  components: {
    performanceTableUserMonth
  },
  props: {

  },
  created() {

    //console.log(JSON.stringify(this.getPerformanceTableItems));
    //wird ausgeführt, bevor das Template geladen wird!

    this.$store.dispatch('setActualMonth', '');
    //this.interval = setInterval(() => this.checkInterval(), 2000);

    //let month = new Date().getMonth() + 1;
    //let year = new Date().getFullYear();

    //console.log('year: ' + year);
    //console.log('month: ' + month);

    //this.$socket.emit('getPerformanceTableData', month, year);

  },
  computed: {
    ...mapGetters([
        'getPerformanceTableItems',
        'getActualMonth',
        'getProjectEditStatus',
        'getPerformanceTableUserMonth',
        'getPerformanceTablePlanBusyState',
        'getPageStatus'
    ]),
    handlePerformanceTableData: {
      get() {
        return this.getPerformanceTableItems;
      },
      set(response) {
        this.$socket.emit('handlePerformanceTableData', response);
      }
    },
    pageStatus() {
      return this.getPageStatus;
    }
  },
  filters: {
    comma2dot(value) {
      return value.replace(',', '.');
    }
  },
  sockets: {
    connect: function () {
      console.log('socket connected');

      var cookie = this.$cookies.get('PHPSESSID');

      //console.log(cookie);

      if (cookie == null) {
        cookie = process.env.VUE_APP_TESTCOOKIE;
      }

      //console.log(cookie);

      this.$socket.emit('sendCookie', cookie);
    },
    cookieReceived: function () {
      console.log('Cookie wurde übermittelt');

      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();

      //console.log('year: ' + year);
      //console.log('month: ' + month);

      this.$socket.emit('getPerformanceTableData', month, year);

    },
    savePerformanceTableData: function (data) {
      console.log('savePerformanceTableData erhalten');
      //console.log(data);
      this.$store.dispatch('savePerformanceTableItems', data.performanceTableItems);
      this.$store.dispatch('togglePerformanceTablePlanBusyState');
      //console.log(this.getPerformanceTableItems);
    },
    savePerformanceTableUserMonth: function (data) {
      console.log('savePerformanceTableUserMonth erhalten');
      //console.log(data.performanceTableUserMonth);
      this.$store.dispatch('savePerformanceTableUserMonth', data.performanceTableUserMonth);
    }
  },
  data() {
    return {
      actual_year: String(new Date().getFullYear()),
      centralUrl: process.env.VUE_APP_CENTRAL_URL,
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setActualMonth',
        'savePerformanceTableItems',
        'changePerformanceTableItemHour',
        'setPerformanceTableItemHourActive',
        'setPerformanceTableItemHourInActive',
        'savePerformanceTableUserMonth',
        'togglePerformanceTablePlanBusyState'
    ]),
    ...mapGetters([
      'getActualUser'
    ]),
    setNewMonth(month) {
      //console.log(month);
      this.$store.dispatch('togglePerformanceTablePlanBusyState');
      this.$store.dispatch('setActualMonth', month);
      //console.log('year: ' + this.actual_year);
      //console.log('month: ' + this.getActualMonth);
      this.$socket.emit('getPerformanceTableData', this.getActualMonth, this.actual_year);
    },
    editHours(index) {
      /*
      var data = this.getPerformanceTableItems.items[index];
      var id = this.actual_year + '-' + this.getActualMonth + '-' + data.project.project_id;
      console.log(id);
      this.$store.dispatch('changeProjectEditStatus', id);
       */

      this.$store.dispatch('setPerformanceTableItemHourActive', index);
    },
    editUser(project_id, user_id) {
      this.$socket.emit('getPerformanceTableUserMonth', this.actual_year, this.getActualMonth, project_id, user_id);

      //console.log(this.getActualUser());
    },
    checkProjectEditStatus(project_id) {

      var pes = this.getProjectEditStatus;

      var id = this.actual_year + '-' + this.getActualMonth + '-' + project_id

      //console.log('ddd' + pes[id]);

      //ist vorhanden?
      if (pes[id] !== undefined) {
        if (pes[id]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeHour(index) {
      //console.log(this.getPerformanceTableItems.items[index].hours.goal);
      //console.log(this.getPerformanceTableItems.items[index].hours.project_id);

      this.$store.dispatch('changePerformanceTableItemHour', index);

    },
    saveHour(index) {

      this.$store.dispatch('setPerformanceTableItemHourInActive', index);

      //console.log(this.getPerformanceTableItems.items[index].hours.goal);
      //console.log(this.getPerformanceTableItems.items[index].hours.project_id);

      //this.$store.dispatch('changePerformanceTableItemHour', index);

      var data = this.getPerformanceTableItems.items[index];

      this.$socket.emit('savePerformanceTableDataProjectHours', this.getPerformanceTableItems.actual_year, this.getPerformanceTableItems.actual_month, data.project.project_id, data.hours.goal);

    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      //console.log($event);
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    filterHeader(data) {
      //console.log(data);

      var out = [];

      for (let i=0; i<data.length; i++) {

        //console.log(data[i].key);

        if (data[i].key.includes('user-')) {
          out.push(data[i]);
        }

      }

      return out;
    },
    getStatistic(type) {
      let stat = this.getPerformanceTableItems.statistic;

      return stat[type];
    },
    getStatisticUser(type) {
      let stat = this.getPerformanceTableItems.statistic;

      return stat.user_statistic[type];
    },
  },
  getCookie() {

    var cookie = this.$cookies.get('PHPSESSID');

    //console.log(cookie);

    if (cookie == null) {
      cookie = process.env.VUE_APP_TESTCOOKIE;
    }

    //console.log(cookie);
    // it gets the cookie called `username`
    //const username = this.$cookies.get("username");
    //console.log(username);

    return cookie;
  },
}
</script>

<style scoped>

.table-link {
  vertical-align: unset;
  padding: 0px;
}

.table-text-input {
  padding: 2px;

}

</style>