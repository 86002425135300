<template>
  <div id="app">
    <navigation></navigation>
    <performance-plan></performance-plan>
  </div>
</template>

<script>
import navigation from "@/components/navigation";
import performancePlan from "@/components/performancePlan";

export default {
  name: 'App',
  components: {
    navigation,
    performancePlan
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
